import React, { useEffect, useState, useRef } from "react";
import "./style.scss";
// import placeholderImage from "../../assets/image/Rectangle.png";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ePub from "epubjs";
import Loader from "assets/image/loader.gif";
import micListeningGif from "assets/image/mic_listening.gif";
import { storage as LocalStorage } from "services/config/storage";
import Navbar from "../../components/Navbar";
import { STORY_DATA } from "../ReadAloud/constants";
import ReadAlongInIt from "./ReadAloudInIt";
import { Redirect } from "react-router-dom";
import * as EVENT from '../../utils/eventKeys'
import { formatDate } from "../../utils/commonUtils";
import version from "../../../package.json";
import { Analytics } from "../../services/analytics";
import { v4 as uuidv4 } from "uuid";


const ReadAloudDetailsPage = () => {

  const [epub_rendition, updateRendition] = useState(null);

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showErrorDiv, setShowErrorDiv] = useState(true);
  const [showTextHighlight, setShowTextHighlight] = useState(true);
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);
  const [apiResponse, setApiResponse] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [count, setcount] = useState(0);
  const [showPlayButton, setShowPlayButton] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState(null);
  const [playbackRate, setPlayBackRate] = useState(1);
  const [showFinishButton, setShowFinishButton] = useState(false);
  const [alreadyPlayed, setAlreadyPlayed] = useState(false);
  const view_id = uuidv4()



  useEffect(() => {

    let story_name = extractStoryNameFromUrl();
    let data = STORY_DATA[story_name];

    loadAndDisplayEPUB(data);
  }, []);

  function extractStoryNameFromUrl() {
    const url = window.location.href;
    const parts = url.split("/");
    const storyName = parts[parts.length - 1];
    return storyName;
  }

  const loadAndDisplayEPUB = async (data) => {
    const book = ePub(data?.url);
    console.log("book:", book);

    // Set default width for rendition
    let renditionWidth = 500;

    // Set default height for rendition
    let renditionHeight = 600;

    // Check if the screen width is less than 600
    if (window.innerWidth < 600) {
      renditionWidth = window.innerWidth - 100;
    }

    // Check if the screen height is less than a certain threshold (e.g., 700)
    if (window.innerHeight < 800) {
      renditionHeight = window.innerHeight - 250;
    }

    const rendition = book.renderTo("epub-file", {
      width: renditionWidth,
      height: renditionHeight,
    });
    updateRendition(rendition);
    console.log(rendition);

    // setShowLoading(true);

    await rendition.display().then(() => {
      console.log("EPUB rendering complete");
      setShowLoading(false);
    });

    rendition.on("rendered", () => {
      const screenWidth = window.innerWidth;
      var iframe = document.querySelector("iframe");
      var iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;

      // Find the image element within the iframe content
      var image_container = iframeDocument.querySelector(".image");
      var image = iframeDocument.querySelector(".image img");
      let body = iframeDocument.querySelector('body')
      let passage_text = document.getElementById("passage-text");

      // Check if the screen width is greater than a certain threshold (e.g., 768px)
      if (screenWidth > 768) {

        // Apply styles to the image
        if (image_container) {
          image_container.style.width = "300px";
          image_container.style.height = "300px";
        }
        if (image) {
          image.style.width = "100%";
          // image_container.style.height = "300px";
        }

        if(body){
          body.style.display ="flex"
          body.style.flexDirection="column"
          body.style.justifyContent="center"
          // body.style.gap="8px"
          body.style.alignItems="center"
        }

        if(passage_text){
          passage_text.style.width="100%"
        }
      }else if(screenWidth <768){
        if (image_container) {
          image_container.style.width = "200px";
          image_container.style.height = "200px";
        }
         if (image) {
           image.style.width = "100%";
           // image_container.style.height = "300px";
         }

        if (body) {
          body.style.display = "flex";
          body.style.flexDirection="column"
          // body.style.justifyContent = "center";
          body.style.overflowY="auto"
          body.style.gap = "8px";
          body.style.alignItems="center"
          body.style.width="fit-content"
        }
      }
    });

    const modalContent = document.querySelector(".modal-content");
    modalContent.classList.add("epub-content");
  };

  const LoadAudio = async () => {
    // console.log("LoadAudio called",epub_rendition,audio);
    var book_name_key = extractStoryNameFromUrl();
    var book_name = STORY_DATA[book_name_key]["name"];
    book_name = book_name.replace(/ /g, "_");
    // console.log(book_name);
    let current_location = epub_rendition.currentLocation();
    console.log("audio", current_location?.start?.index);
    let index = current_location?.start?.index;
    console.log("index: ", index);

    if (index == 0) {
      var playbackRate = document.querySelector(".playback-rate");
      playbackRate.classList.add("d-none");
    }
    if (index >= 4) {
      var iframe = document.querySelector("iframe");

      // document.getElementById('passage-audio').style.display='block'
      document.getElementById("playAudio").style.display = "unset";
      // document.getElementsByClassName("playback-rate")[0].style.visibility =
      //   "visible";
      document.getElementById("passage-text").style.display = "block";
      var page = index - 3;

      var filen = "sentence" + String(page);
      console.log(book_name, filen);
      // fetch('/read-along/audio/'+String(name_of_book)+'/Text/'+filen+'.txt')
      console.log("before fetching text file");
      await fetch(
        "https://freadom-beta-bucket.s3.ap-south-1.amazonaws.com/read_aloud/audio-20240120T054727Z-001/audio/" +
          String(book_name) +
          "/Text/" +
          filen +
          ".txt"
      )
        .then((response) => response.text())
        .then(async (data) => {
          // Do something with your data
          console.log("got the txt response");
          console.log(data);
          var iframe = document.querySelector("iframe");
          var targetHtml = "";
          var ePubTextList =
            iframe.contentWindow.document.querySelectorAll("p");
          console.log("got the paragraph list:", ePubTextList);
          ePubTextList.forEach(function (p) {
            targetHtml += p.outerHTML;
            p.remove();
          });
          var ifrDoc = iframe.contentDocument;
          console.log("got the ifrDoc:", ifrDoc);

          // remove the old passage
          var oldPassage = ifrDoc.getElementById("passage-text");
          if (oldPassage) {
            oldPassage.remove();
          }
          var cssLink = document.createElement("style");
          cssLink.innerHTML = `
          .initialized span[data-begin]:focus,
          .initialized span[data-begin]:hover {
              background-color: #FFFFCA;
              box-shadow: 0px 0px 4px #FFFFCA;
              padding: 4px;
          }
          .initialized span[data-begin].speaking {
              background-color: yellow;
              box-shadow: 0px 0px 4px yellow;
              padding: 4px;
          }
          .initialized span[data-begin] {
              cursor: pointer;
          }
          `;
          iframe.contentWindow.document.head.appendChild(cssLink);

          var elem = ifrDoc.createElement("div");
          console.log("got the elem:", elem);
          elem.classList.add("passage");
          elem.setAttribute("id", "passage-text");
          elem.innerHTML = data;
          ifrDoc.body.appendChild(elem);
          ifrDoc.body.classList.add("initialized");

          // document.getElementById("passage-text").innerHTML=data;
        });
      console.log("before fetching audio file:", filen);
      // fetch('/read-along/audio/'+String(name_of_book)+'/Test/'+filen+'.wav')
      var audio_url =
        "https://freadom-beta-bucket.s3.ap-south-1.amazonaws.com/read_aloud/audio-20240120T054727Z-001/audio/" +
        String(book_name) +
        "/" +
        filen +
        ".wav";
      var audio_file = new Audio(audio_url);
      setAudio(audio_file);

      removeClassNone();

      var args = {
        text_element:
          iframe.contentWindow.document.getElementById("passage-text"),
        audio_element: document.getElementById("passage-audio"),
        autofocus_current_word: true,
      };
      console.log("args:", args);
      ReadAlongInIt.init(args);
    } else {
      document.getElementById("passage-audio").style.display = "none";
      document.getElementById("playAudio").style.display = "none";
      // document.getElementsByClassName("playback-rate")[0].style.visibility =
      //   "hidden";
      document.getElementById("passage-text").style.display = "none";
    }
    // ChangeEPubSize();
  };

  const removeClassNone = () => {
    var element = document.querySelector(".passage-audio");
    element.classList.remove("d-none");
  };

  // write function to load epub data from storyData.url
  const prevPage = async () => {
    setAlreadyPlayed(false);
    setAudio(null);
    setcount(count - 1);
    console.log(count);
    if (count === 0) {
      setShowPrevButton(false);
      setShowNextButton(true);
      return;
    }

    if (epub_rendition.location.start.index === 4) {
      setShowPlayButton(true);
      await epub_rendition.display(0);

      epub_rendition.display(0).then(async () => {
        console.log("rendered 2nd page");
        console.log("index:", epub_rendition?.location?.start?.index);
        updateRendition(epub_rendition);
        setShowPrevButton(false);
        await LoadAudio();
      });
    } else {
      // await epub_rendition.prev();
      // await LoadAudio();

      let prevDone = new Promise(async (res, reject) => {
        res(await epub_rendition.prev());
      });

      prevDone.then(async (res) => {
        console.log("index:", epub_rendition?.location?.start?.index);

        await LoadAudio();
        updateRendition(epub_rendition);
      });

      // await epub_rendition.prev()
      // epub_rendition.prev().then(async () => {
      //   console.log("rendered middle page");
      //   console.log("index:", epub_rendition?.location?.start?.index);
      //   await LoadAudio();
      //   updateRendition(epub_rendition);
      // });
      setShowFinishButton(false);
    }

    // Check if the current page index is the first index
    if (epub_rendition.location.atStart) {
      console.log("in first page");
      setShowPrevButton(false);
      setShowNextButton(true);
      // select the p tag with classname playback-rate
      playbackRate.classList.remove("d-none");
    } else {
      setShowPrevButton(true);
      setShowNextButton(true);
    }

    let targetHtml = "";
    var highlightText = document.getElementById("textHighlight");
    var errorDiv = document.getElementById("error-message");
    var errorMessage = document.getElementById("error-message-full");
    var tryAgainButton = document.getElementById("try-again-button");
    var recordButton = document.getElementById("recordButton");

    highlightText.innerHTML = "?";
    highlightText.style.display = "none";

    if (epub_rendition) {
      console.log("rendition: ", epub_rendition);
    }
  };

  const nextPage = async () => {
    console.log("reached next page");
    // console.log("index:", epub_rendition?.location?.atEnd);
    // if (epub_rendition?.location?.atEnd) {
    //   console.log("Already at the last page");
    //   setShowFinishButton(true);
    //   setShowNextButton(false);
    //   // window.location = "/read-aloud/";
    // } else {
    //   setShowFinishButton(false);
    // }
    // console.log(count);
    setcount(count + 1);

    if (count >= 0) {
      // setShowPrevButton(true);
    }

    // Check if the current location is already at the end

    if (epub_rendition.location.start.index < 4) {
      console.log("in thumbnail");
      setShowPlayButton(true);
      await epub_rendition.display(4);
      epub_rendition.display(4).then(async () => {
        console.log("rendered 2nd page");
        console.log("index:", epub_rendition?.location?.start?.index);
        updateRendition(epub_rendition);
        await LoadAudio();
      });
    } else {
      setShowPlayButton(true);
      let nextDone = new Promise(async (res, reject) => {
        res(await epub_rendition.next());
      });

      console.log("nextDone", nextDone);
      nextDone.then(async (res) => {
        console.log("index:", epub_rendition?.location?.start?.index);
        console.log("prev", epub_rendition);
    console.log("index 407:", epub_rendition?.location?.atEnd);

        await LoadAudio();
        updateRendition(epub_rendition);
        let current_location = epub_rendition.currentLocation();
        console.log("new", epub_rendition, current_location);
         if (current_location?.atEnd) {
           console.log("Already at the last page");
           setShowFinishButton(true);
           setShowNextButton(false);
           // window.location = "/read-aloud/";
         } else {
           setShowFinishButton(false);
         }
      });
    }
    // epub_rendition.next().then(async () => {
    //   console.log("rendered middle page");
    // });

    // call loadAudio only after epub_rendition.display() promise

    // }

    // Check if the current page index is the last index
    if (epub_rendition.location.atEnd) {
      console.log("reached end");
      setShowNextButton(false);
      setShowPrevButton(true);
    } else {
      console.log("mid");

      setShowNextButton(true);
      setShowPrevButton(true);
    }
    ChangeEPubSize();
    checkIfOnTerminal();

    if (epub_rendition) {
      console.log("rendition: ", epub_rendition);
    }

    if (epub_rendition.location.atStart) {
      var playbackRate = document.querySelector(".playback-rate");
      playbackRate.classList.remove("d-none");
      playbackRate.classList.add("d-true");
    }
    console.log("line above playback rate");
  };

  const playAudio = () => {
    console.log("clicked on play button");
    let thisEle = document.getElementById("playAudio");
    var audio = document.getElementById("passage-audio");
    console.log(audio);
    if (!audio.paused) {
      thisEle.className = "btn btn-info";
      thisEle.innerHTML = `<span><i class="fa fa-play"></i></span>&nbsp;&nbsp; Play`;
      audio.pause();
    } else {
      thisEle.className = "btn btn-danger";
      thisEle.innerHTML = `<span><i class="fa fa-pause"></i></span>&nbsp;&nbsp;<span>Paused</span>`;
      audio.play();
    }
  };
  const playPassageAudio = () => {
    console.log("clicked on stop audio");
  };
  const checkIfOnTerminal = () => {
    let prev = document.getElementById("prev");
    let next = document.getElementById("next");
    if (epub_rendition.location?.atStart) {
      prev.setAttribute("disabled", true);
    } else {
      prev.setAttribute("disabled", false);
    }

    if (epub_rendition.location?.atEnd) {
      // next.setAttribute("disabled", true);
      setShowNextButton(false);
    } else {
      next.setAttribute("disabled", false);
    }
  };

  const ChangeEPubSize = () => {
    console.log("click here");
    const iFrame = document.querySelector("iframe");

    const ePubImg = iFrame.contentWindow.document.querySelector("img");
    const ePubBody = iFrame.contentWindow.document.querySelector("body");
    const ePubDiv = document.querySelector("#epub-file div div");
    const ePubHtml = iFrame.contentWindow.document.querySelector("html");

    if (ePubImg !== null) {
      ePubImg.style.paddingBottom = "0px";
    }

    ePubHtml.style.margin = "unset";
    ePubDiv.style.height = "83vh";
    ePubBody.style.height = "83vh";
  };
  //   const handlePlayPause = () => {
  //     if (!audio) {
  //         return;
  //     }

  //     console.log("clicked on play pause button, ", playbackRate, audio);
  //     const audio_ele = document.getElementById('passage-audio');
  //     audio.playbackRate = playbackRate;

  //     console.log("audio playback rate:", audio.playbackRate);
  //     if (!audio.paused) {
  //         setIsPlaying(false);
  //         audio.pause();
  //         var currentTime = new Date();
  //         setPausetime(currentTime.getTime())

  //       // audio.currentTime = 0; // Reset the audio file
  //     } else {
  //         setIsPlaying(true);
  //         audio.play();
  //     }

  //     audio.addEventListener('ended', () => {
  //         setIsPlaying(false);
  //         // Replace the text inside the play/pause button with "Play"
  //         audio_ele.innerText = "Play";
  //     });

  //     let iframe = document.querySelector("iframe");

  //     var args = {
  //         text_element:
  //         iframe.contentWindow.document.getElementById("passage-text"),
  //         audio_element: document.getElementById("passage-audio"),
  //         autofocus_current_word: true,
  //         audio: audio,
  //         playbackRate: playbackRate,
  //     };

  //     console.log("args:", args);
  //     if(audio.paused)
  //     {

  //     }
  //     console.log("condition", !audio.paused && !playbuttonCLicked);
  //     if (!audio.paused && !playbuttonCLicked) {
  //         console.log("inside if");
  //         ReadAlongInIt.playButtonPress(args);
  //         setPlaybuttonClicked(true);
  //     }
  //     else if(!audio.paused){

  //       //From here, I need to send the Word Count
  //       var current_time=currentTime.getTime();
  //       var pauseduration=current_time - pausetime;

  //       console.log("pausetime",pausetime);
  //       if(pausetime != 0)
  //       {
  //         var currentTime=new Date();
  //         var pauseduration=currentTime.getTime() - pausetime;
  //             console.log("in readaloud pauseduration",pauseduration);
  //             setPausetotal(pausetotal+pauseduration);
  //       }
  //             ReadAlongInIt.playButtonPressed2ndTime(args,pausetotal);

  //     }

  //     setAlreadyPlayed(true);

  // };

  const logEvents = (event, extraPayload) => {
    let payload = {};
    payload[EVENT.SOURCE_PAGE_ID] = EVENT.READ_TO_ME_PAGE;
    payload[EVENT.VIEW_ID] = view_id;
    Analytics.logEvents(event, { ...payload,...extraPayload });
  };


  const handlePlayPause = () => {
    var book_name_key = extractStoryNameFromUrl();
    var book_name = STORY_DATA[book_name_key]["name"];
    var book_url = STORY_DATA[book_name_key]["url"];
    if (!audio) {
      return;
    }
    console.log("clicked on play pause button, ", playbackRate, audio);
    const audio_ele = document.getElementById("passage-audio");
    audio.playbackRate = playbackRate;

    console.log("audio playback rate:", audio.playbackRate);
    if (!audio.paused) {
      setIsPlaying(false);
      audio.pause();
      audio.currentTime = 0; // Reset the audio file
      setShowNextButton(true);
      setShowPrevButton(true);
       logEvents(EVENT.READ_TO_ME_STOP_BUTTON, {
         [EVENT.READ_TO_ME_STORY_NAME]: book_name,
         [EVENT.READ_TO_ME_STORY_URL]: book_url,
        });
      } else {
        setIsPlaying(true);
        audio.play();
        setShowNextButton(false);
        setShowPrevButton(false);

        logEvents(EVENT.READ_TO_ME_PLAY_BUTTON,{
          [EVENT.READ_TO_ME_STORY_NAME]:book_name,
          [EVENT.READ_TO_ME_STORY_URL]: book_url,
     })
    }

    audio.addEventListener("ended", () => {
      console.log("audio ended");
      setShowNextButton(true);
      setShowPrevButton(true);
      setIsPlaying(false);
      // Replace the text inside the play/pause button with "Play"
      audio_ele.innerText = "Play";
    });

    let iframe = document.querySelector("iframe");

    var args = {
      text_element:
        iframe.contentWindow.document.getElementById("passage-text"),
      audio_element: document.getElementById("passage-audio"),
      autofocus_current_word: true,
      audio: audio,
      playbackRate: playbackRate,
    };
    console.log("args:", args);
    ReadAlongInIt.playButtonPress(args);
  };

  useEffect(() => {
    const audio = document.getElementById("passage-audio");

    const handlePause = () => {
      setIsPlaying(false);
    };

    audio.addEventListener("pause", handlePause);

    return () => {
      audio.removeEventListener("pause", handlePause);
    };
  }, []);

  const isMiddlePage = () => {
    if (epub_rendition.location.start.index > 4) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    const playButtonRef = document.querySelector(".play-button");
    console.log("play button ref:", playButtonRef);

    if (playButtonRef && alreadyPlayed && isMiddlePage()) {
      console.log("if ");

      const delay = 1500;
      setTimeout(() => {
        playButtonRef.click();
      }, delay);
    }
  }, [audio, alreadyPlayed]);

  const updatePlaybackRate = (e) => {
    const newPlaybackRate = parseFloat(e.target.value);
    setPlayBackRate(newPlaybackRate);
  };

  const FinishStory = async () => {
    var book_name_key = extractStoryNameFromUrl();
    var book_name = STORY_DATA[book_name_key]["name"];
    var book_url = STORY_DATA[book_name_key]["url"];
    logEvents(EVENT.READ_TO_ME_EXIT_BUTTON, {
      [EVENT.READ_TO_ME_STORY_NAME]: book_name,
      [EVENT.READ_TO_ME_STORY_URL]: book_url,
    });
    window.location = "/read-aloud";


    // history.push("/read-aloud");
  };

  return (
    <div>
      <Navbar />
      <div className="read_aloud_container">
        {/* <div className="navigation-icons"> */}
        {/* {console.log("state", showPrevButton)} */}
        {showPrevButton ? (
          <div className="left-icon" id="prev" onClick={prevPage}>
            <FaChevronLeft className="nav-icon leftnav pdf_control_btn" />
          </div>
        ) : (
          <div />
        )}
        {/* {console.log("state", showPrevButton)} */}
        <div className="modal-content">
          <div
            style={{
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              backgroundColor: "#F1EEF5",
            }}
          ></div>

          <div id="epub-file"></div>
          {showErrorDiv ? (
            <div className="errorDiv">
              <p id="error-message"></p>
              <p id="highlight-text"></p>
            </div>
          ) : null}
          <div>
            <div id="error-message" className="col-md-12">
              {showErrorMessage ? (
                <h2>
                  Error: <span id="error-message-full">?</span>
                </h2>
              ) : null}
            </div>
          </div>
          <div>
            <div
              id="api-response-text"
              dangerouslySetInnerHTML={{ __html: apiResponse }}
              className="col-md-12"
            >
              {/* {apiResponse} */}
            </div>
          </div>

          <div className="row text-center">
            <div
              style={{
                width: "100%",
                marginBottom: "0px",
                marginTop: "-10px",
                display: "none",
                verticalAlign: "baseline",
              }}
              id="mic_listening"
            >
              <img
                style={{ height: "5rem" }}
                className="img-recorder"
                src={micListeningGif}
                alt="Mic Listening"
              />
              <span style={{ fontSize: "1.4rem" }}>Recording now</span>
            </div>
          </div>
          <div>
            {showLoading ? (
              <img
                style={{ height: "5rem" }}
                className="img-recorder"
                src={Loader}
                alt="loading"
              />
            ) : null}
          </div>
        </div>
        {showNextButton ? (
          <div className="right-icon" id="next" onClick={nextPage}>
            <FaChevronRight className="nav-icon rightnav pdf_control_btn" />
          </div>
        ):
        <div className="right-icon"/>
      }
        {/* </div> */}
      </div>

      <p
        className="passage-audio text-center d-none d-true"
        style={{ display: "flex", flexDirection: "column !important" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p className="playback-rate big-div ">
            <span title="Note that increasing the reading rate will decrease accuracy of word highlights">
              <label
                className="playback-label"
                htmlFor="playback-rate"
                style={{
                  fontWeight: "bolder",
                  marginRight: "6px",
                  marginTop: "10%",
                }}
              >
                Reading rate:
              </label>
              <input
                id="playback-rate"
                type="range"
                min="0.5"
                max="1.2"
                value={playbackRate}
                step="0.1"
                onChange={updatePlaybackRate}
              />
              <output id="playback-rate-output">{playbackRate}&times;</output>
            </span>
          </p>

          <div className="play-exit-btns">
            <button
              type="button"
              id="playAudio"
              className={`btn play-button ${
                isPlaying ? "btn-danger" : "btn-info"
              }`}
              onClick={handlePlayPause}
            >
              <span>
                <i className={`fa ${isPlaying ? "fa-stop" : "fa-play"}`}></i>
              </span>
              &nbsp;&nbsp;
              {isPlaying ? "Stop" : "Play"}
            </button>
            {showFinishButton && (
              <button
                className="finishbtn"
                onClick={FinishStory}
                id="recordButton"
              >
                Exit Story
              </button>
            )}
          </div>
        </div>
        <audio id="passage-audio" className="passage d-none" controls>
          <em className="error">
            <strong>Error:</strong> Your browser doesn't appear to support HTML5
            Audio.
          </em>
        </audio>
      </p>

      <div id="passage-text" class="passage"></div>
      {showTextHighlight ? (
        <div
          id="textHighlight"
          // class="row text-center justify-content-center"
          className="row text-center justify-content-center"
          // style="margin-top: 20px; display: none"
          style={{ marginTop: "20px", display: "none" }}
        >
          ?
        </div>
      ) : null}
    </div>
  );
};

export default ReadAloudDetailsPage;
