import { Spin, Icon } from 'antd'
import React from 'react'

const EpubButton = ({ src, alt, text, isDisabled = false, handleOnClick, main = false, stop = false, btnClass }) => {
  const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />;

  return (
    <div onClick={handleOnClick} className='epub_button_container'>
      {
        btnClass === "stop_recording_btn" ?
          <div className="button_container_waves">
            <button className={`epub_button ${main ? "main" : "normal"} ${btnClass ? btnClass : ""}`} disabled={isDisabled}>
              {
                src ?
                  <img src={src} alt={alt} />
                  :
                  <Spin indicator={antIcon} />

              }
            </button>
            <div className="waves" />
          </div>
          :
          <button className={`epub_button ${main ? "main" : "normal"} ${btnClass ? btnClass : ""}`} disabled={isDisabled}>
            {
              src ?
                <img src={src} alt={alt} />
                :
                <Spin indicator={antIcon} />
            }
          </button>
      }
      {
        text &&
        <p className='epub_btn_text'>{text}</p>
      }
    </div>
  )
}

export default EpubButton