import React, { useEffect, useState } from "react";
import "./index.scss";
import { Select } from "antd";
import { connect } from "react-redux";
import moment from "moment";
const { Option } = Select;
const BatchSelector = ({ onSelect, ...props }) => {
  let defaultBatch = props.student?.batchDetails?.[0];
  const [activeBatch, setActiveBatch] = useState(
    defaultBatch?.name || getAlternateBatchName(defaultBatch?.start_timestamp)
  );
  useEffect(() => {
    // useForceUpdate();
  }, [defaultBatch?.name]);
  function useForceUpdate() {
    setActiveBatch(
      defaultBatch?.name || getAlternateBatchName(defaultBatch?.start_timestamp)
    ); // update the state to force render
  }
  return (
    <div className={"batch-selector-container"}>
      <Select
        className={"batch-selector"}
        onChange={(data) => {
          onSelect && onSelect(data);
          setActiveBatch(data);
        }}
        value={activeBatch}
        style={{ width: "100%" }}
        dropdownClassName="custom-dropdown"
      >
        {props.student?.batchDetails?.map((element) => {
          return (
            <Option value={element.batch_id}>
              {element.name || getAlternateBatchName(element?.start_timestamp)}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};
const getAlternateBatchName = (startTime) => {
  if (moment(startTime).toDate() > moment().toDate()) {
    return `Batch starting on ${moment(startTime)?.format("Do MMM")}`;
  } else {
    return `Batch started on ${moment(startTime)?.format("Do MMM")}`;
  }
};
export default connect(({ student }) => ({ student }), null)(BatchSelector);
