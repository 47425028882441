import React, { useEffect, useState, useRef } from "react";
import Navbar from "../../components/Navbar";
import EpubReader from "../../components/EpubReader/EpubReader";

import _debounce from "lodash/debounce";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as storyActions from "store/story/actions";
import { API_URL } from "../../services/config/apiUrl";
import { STORY_DATA } from "../ReadAloud/constants";
import { storage as LocalStorage } from "services/config/storage";
import Loader from "assets/image/loader.gif";
import CloseBlackIcon from 'assets/icons/close_epub.svg';

import "./style.scss";
import '../../components/MediaViewer/MediaViewer.scss'
import ReadandSpeakLoader from "../../components/ReadandSpeakLoader";

const StoryReadAloud = ({ data }) => {
    const [showStartButton, setShowStartButton] = useState(false);
    const [recorder, setRecorder] = useState(null);
    const [session_id, setSessionId] = useState("");
    const [process_id, setProcessId] = useState("");
    const [error, setError] = useState(null);
    const [stopRecButton, setStopRecButton] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [isReadAloud, setIsReadAloud] = useState(false);
    const [playbackRate, setPlayBackRate] = useState(1);
    const [selections, setSelections] = useState([]);

    const [audioFile, setAudioFile] = useState(null)
    const [currentWordIndex, setCurrentWordIndex] = useState(-1);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [locationChanged, setLocationChanged] = useState(false)
    const [lastPage, setLastPage] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [tocLength, setTocLength] = useState(0)
    const [micLoader, showMicLoader] = useState(false)
    const [subtitleData, setSubtitleData] = useState(null);
    const [isAudioLoading, setIsAudioLoading] = useState(false)
    const [isPaused, setIsPaused] = useState(false)

    const audioRef = useRef(null);
    const viewerRef = useRef(null);
    const renditionRef = useRef(null);
    const speechSynthesisRef = window.speechSynthesis

    function extractStoryNameFromUrl() {
        const url = window.location.href;
        const parts = url.split("/");
        const storyName = parts[parts.length - 1];
        return storyName;
    }

    function parseHTMLText(htmlString, playbackSpeed = 1) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");
        const spans = doc.querySelectorAll("span");

        const result = Array.from(spans).map((span, index) => {
            const text = span.textContent.trim();
            const originalStart = parseFloat(span.getAttribute("data-begin"));
            const originalDuration = parseFloat(span.getAttribute("data-dur"));
            // Adjust start time and duration based on playback speed
            const start = (originalStart);
            const duration = originalDuration / playbackSpeed;
            const end = start + duration;

            return { text, start, end, duration };
        });
        setSubtitleData(result)
    }

    const LoadAudio = async () => {
        setIsAudioLoading(true)
        var book_name_key = extractStoryNameFromUrl();
        var book_name = STORY_DATA[book_name_key]["name"];
        book_name = book_name.replace(/ /g, "_");
        // console.log(book_name);
        let current_location = renditionRef.current.currentLocation();

        let index = current_location?.start?.index;

        if (index >= 4) {
            var page = index - 3;

            var filen = "sentence" + String(page);

            await fetch(
                "https://freadom-beta-bucket.s3.ap-south-1.amazonaws.com/read_aloud/audio-20240120T054727Z-001/audio/" +
                String(book_name) +
                "/Text/" +
                filen +
                ".txt"
            )
                .then((response) => response.text())
                .then(async (data) => {
                    parseHTMLText(data, playbackRate)
                });

            var audio_url =
                "https://freadom-beta-bucket.s3.ap-south-1.amazonaws.com/read_aloud/audio-20240120T054727Z-001/audio/" +
                String(book_name) +
                "/" +
                filen +
                ".wav";

            audioRef.current.src = audio_url
            audioRef.current.playbackRate = playbackRate
        }
        setIsAudioLoading(false)
    };

    const handleTimeUpdate = () => {
        if (!audioRef.current || subtitleData?.length === 0) return;

        const currentTime = audioRef.current.currentTime; // Convert to ms
        // Find the current word based on time

        const currentIndex = subtitleData?.findIndex(
            (word) => currentTime >= word.start && currentTime < word.end
        );

        if (currentIndex !== currentWordIndex) {
            setCurrentWordIndex(currentIndex);
        }
    };

    const recordAudio = () =>
        new Promise(async (resolve) => {
            // console.log("inside rec promise",navigator.mediaDevices);
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            // console.log("stream", stream);
            //   setStream(stream);
            const mediaRecorder = new MediaRecorder(stream);
            const audioChunks = [];
            // console.log(stream);
            // console.log(mediaRecorder);

            mediaRecorder.addEventListener("dataavailable", (event) => {
                audioChunks.push(event.data);
            });

            const start = () => {
                mediaRecorder.start();
                // setRecording(true);
            };

            const stop = () =>
                new Promise((resolve) => {
                    // console.log("called stop method");
                    mediaRecorder.addEventListener("stop", () => {
                        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
                        const audioUrl = URL.createObjectURL(audioBlob);
                        const audio = new Audio(audioUrl);
                        const play = () => audio.play();
                        // setRecording(false);
                        // setAudioPlayer({ audioBlob, audioUrl, play });
                        resolve({ audioBlob, audioUrl, play });
                    });
                    // console.log("media recorder state", mediaRecorder.state);
                    if (mediaRecorder.state !== "inactive") {
                        mediaRecorder.stop();
                    }
                });

            resolve({ start, stop });
        });

    function getRandomID() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
    }

    const getShownText = () => {
        // console.log("get shown func");
        var range = renditionRef?.current.getRange(
            renditionRef?.current.currentLocation().start.cfi
        );
        var endRange = renditionRef?.current.getRange(
            renditionRef?.current.currentLocation().end.cfi
        );
        // console.log(range);
        // console.log(endRange);
        range.setEnd(endRange.startContainer, endRange.startOffset);
        let targetPhrase = range.toString();
        // setTargetPhrase(targetPhrase);
        // console.log(targetPhrase);
    };

    const stopRecording = async () => {
        showMicLoader(false)
        setShowStartButton(true);
        setStopRecButton(false);
        setShowLoading(true);
        setShowStartButton(false);
        try {
            // console.log("try");
            const audio = await recorder.stop();
            // console.log("audio:", audio);

            var wavfromblob = new File([audio.audioBlob], "incomingaudioclip.wav");
            var audioFormdata = new FormData();
            audioFormdata.append("file", wavfromblob, "New Recording.wav");

            const response = await fetch(
                `${API_URL}misc/v1/media-v2/`,
                {
                    method: "post",
                    body: audioFormdata,
                }
            );

            if (!response.ok) {
                // Handle HTTP errors (e.g., 4xx, 5xx)
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const text = await response.json();
            // var token = LocalStorage.fetch.authToken();
            // console.log("Token", token);

            const fluencyResponse = await fetch(
                `${API_URL}misc/v1/reading-fluency-iitb/`,
                {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                        // authorization: `JWT ${token}`,
                    },
                    body: JSON.stringify({
                        audio_path: text?.result?.file,
                        book_title: data?.name === "Lets Go to the Zoo" ? "Let's Go to the Zoo" : data?.name,
                        process_id: process_id,
                        session_id: session_id,
                        grade: LocalStorage.fetch.defaultChild()?.grade ? LocalStorage.fetch.defaultChild()?.grade?.name : 'Test POC',
                        page: renditionRef?.current.location.start.index - 3,
                    }),
                }
            );

            if (!fluencyResponse.ok) {
                // Handle API errors
                const fluencyError = await fluencyResponse.json();
                const errorMessage =
                    fluencyError?.ExceptionMessage ||
                    "Internal Server Error. Contact Administrator.";

                // Update loader and button states
                setShowLoading(false);
                setStopRecButton(false);
                setShowStartButton(true);
                setError(errorMessage);
            } else {
                setAudioFile(text?.result?.file)
                setError(null);
                let resp = await fluencyResponse.json();
                const allPTags = document.querySelectorAll("p");

                var iframe = document.querySelector("iframe");
                var targetHtml = " ";
                var ePubTextList = iframe.contentWindow.document.querySelectorAll("p");
                // console.log("epublist", ePubTextList);
                // var imagecontainer =
                //     iframe.contentWindow.document.querySelectorAll("img");
                // // console.log("imagecontainer", imagecontainer);
                // if (imagecontainer.length > 0) {
                //     imagecontainer[0].style.width = "200px";
                //     imagecontainer[0].style.height = "200px";
                // }
                ePubTextList.forEach(function (p) {
                    targetHtml += p.outerHTML;
                    p.remove();
                });
                var ifrDoc = iframe.contentDocument;

                var elem = ifrDoc.createElement("div");

                elem.innerHTML = resp?.result?.marked_text;
                ifrDoc.body.appendChild(elem);

                setShowLoading(false);
                setShowStartButton(true);
            }
        } catch (error) {
            console.error("Error in stopRecording:", error);

            // Update loader and button states
            setShowLoading(false);
            setStopRecButton(false);
            setShowStartButton(true);
            setError(error);
        }
    };

    const startRecording = async () => {
        // console.log("start func");
        showMicLoader(true)
        renditionRef.current.annotations.remove(selections.cfiRange, "highlight");
        speechSynthesisRef.cancel()
        setIsReadAloud(false)
        setAudioFile(null)

        if (error) setError(false);

        var session_id = getRandomID();
        setSessionId(session_id);
        // console.log(session_id);
        var process_id = getRandomID();
        setProcessId(process_id);

        setStopRecButton(true);
        getShownText();

        var recorder = await recordAudio();
        setRecorder(recorder);
        // console.log("Recorder", recorder);
        recorder.start();

    };

    const FinishStory = async () => {
        setAudioFile(null)
        window.location = "/read-along";
    };

    useEffect(() => {
        if (error !== null) {
            var iframe = document.querySelector("iframe");
            var targetHtml = " ";
            var ePubTextList = iframe.contentWindow.document.querySelectorAll("p");
            if (error) {
                ePubTextList.forEach(function (p) {
                    targetHtml += `<p style="color:red;margin:0">${p.innerText}</p>`;
                    // console.log(targetHtml);
                    p.remove();
                });
                var ifrDoc = iframe.contentDocument;
                var elem = ifrDoc.createElement("div");
                elem.innerHTML = targetHtml;
                ifrDoc.body.appendChild(elem);
            } else {
                ePubTextList.forEach(function (p) {
                    targetHtml += `<p>${p.innerText}</p>`;
                    // console.log(targetHtml);
                    p.remove();
                });
                var ifrDoc = iframe.contentDocument;
                var elem = ifrDoc.createElement("div");
                elem.innerHTML = targetHtml;
                ifrDoc.body.appendChild(elem);
            }
        }
    }, [error]);

    const startReadAloud = () => {
        audioRef.current.play();
        setCurrentWordIndex(-1)
    };

    const handleNext = async () => {
        if (currentPageIndex < tocLength) {
            setAudioFile(null)
            showMicLoader(false)
            if (renditionRef.current.location.start.index < 4) {
                setShowStartButton(true);
                await renditionRef.current.display(4);
            } else {
                setShowStartButton(true);
                await renditionRef.current.next()
            }

            if (locationChanged) {
                setCurrentPageIndex(currentPageIndex + 1)
                setLocationChanged(false)
                if (renditionRef.current.currentLocation()?.atEnd) {
                    setLastPage(true)
                }
            }
            // speechSynthesisRef.cancel();
            // setIsReadAloud(false)
            // setIsPaused(false)
            await LoadAudio()
            // setTextNodes()
        } else {
            setLastPage(true)
        }
    };

    const handlePrev = async () => {
        setAudioFile(null)
        showMicLoader(false)
        setLastPage(false)
        setCurrentPageIndex(currentPageIndex - 1)

        if (renditionRef?.current.location.start.index == 4) {
            setShowStartButton(false);
            await renditionRef?.current.display(0);
        } else {
            await renditionRef.current.prev()
        }
        await LoadAudio()
    };

    const handleBookLoaded = async (book) => {
        const toc = renditionRef?.current?.book?.spine?.items; // Access the TOC from the book
        setTocLength(toc?.length);

        if (viewerRef.current) {
            const iframeDocument = viewerRef.current.querySelector('iframe')?.contentWindow?.document
            // Find the image element within the iframe content
            let image = iframeDocument.querySelector(".image");
            let para = iframeDocument.querySelectorAll("p");

            if (!image) {
                if (para[0]) para[0].style.marginTop = '1rem'
            }

        };
    };

    return (
        <div style={{ minHeight: '100dvh', display: 'flex', justifyContent: "flex-end", flexDirection: 'column' }}>
            <Navbar />
            <div className="read_to_me_epub_container">

                <EpubReader url={data?.url} bookLoadedFunc={handleBookLoaded} isLoaded={isLoaded} handleNextFunc={handleNext} handlePrevFunc={handlePrev} renditionRef={renditionRef} setIsLoaded={setIsLoaded} currentPageIndex={currentPageIndex} lastPage={lastPage} viewerRef={viewerRef} tocLength={tocLength} setLocationChanged={setLocationChanged} selections={selections} setSelections={setSelections} startRecording={startRecording} stopRecording={stopRecording} isRecording={stopRecButton} source={'ReadandSpeak'} readAloudFunc={startReadAloud} isAudioLoading={isAudioLoading} readAloudAudioRef={audioRef} playbackRate={playbackRate} setPlayBackRate={setPlayBackRate} currentWordIndex={currentWordIndex} showButtons={showStartButton} isReadAloud={isReadAloud} setIsReadAloud={setIsReadAloud} finishStory={FinishStory} showingLoader={showLoading} setIsPaused={setIsPaused} isPaused={isPaused} />

                <audio
                    ref={audioRef}
                    controls
                    onTimeUpdate={handleTimeUpdate}
                    onEnded={() => {setIsPaused(false); setIsReadAloud(false) }}
                    style={{ display: "none" }}
                    playbackRate={playbackRate}
                ></audio>
                {
                    showStartButton && !lastPage &&
                    <img src={CloseBlackIcon} className="read_along_icon_close pdf_control_btn" alt="X" onClick={FinishStory} />
                }

                {/* {
                    audioFile && <div className="read_along_recorded-audio">
                        <div className="read_along_audio_block">
                            <audio
                                src={audioFile}
                                controls="controls"
                                className="read_along_audio_element"
                            />
                        </div>
                        <div
                            className="audio_close_btn"
                            onClick={() => {
                                setAudioFile(null)
                            }}
                        >
                            X
                        </div>
                    </div>
                } */}

                {/* {
                    micLoader &&

                    <div
                        className="read_along_mic_loader"
                        id="mic_listening"
                    >
                        <img
                            style={{ height: "3rem", marginTop: "4px", marginBottom: "4px" }}
                            className="img-recorder"
                            src={micListeningGif}
                            alt="Mic Listening"
                        />
                        <span style={{ fontSize: "15px" }}>Recording now</span>
                    </div>

                } */}
                {showLoading ? (
                    <div style={{ display: "flex", justifyContent: "center", paddingBottom: '2rem' }}>
                        {/* <img
                            style={{ height: "4rem", justifyContent: "center" }}
                            className="loading-symbol"
                            src={Loader}
                            alt="loading"
                            id="load"
                        /> */}
                        <ReadandSpeakLoader/>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default connect(
    ({ detailedStory }) => ({ ...detailedStory }),
    (dispatch) => bindActionCreators({ ...storyActions }, dispatch)
)(StoryReadAloud);
