import React, { useState } from "react";
import nlp from "compromise";
import speechPlugin from "compromise-syllables";
// import RiTa from "rita";
import { RiTa } from "https://esm.sh/rita";


const getSyllablesRiTa = (word) => {
  console.log(RiTa.analyze(word))
  return RiTa.syllables(word, { simple: true })
    .split("/")
    .map((syl) => syl.replace(/\W/g, ""));; // Returns actual syllables
};

nlp.plugin(speechPlugin);

const useSyllables = () => {
  const [syllables,setSyllables] = useState([])

  const getSyllables = (word)=>{
    if(!word) return
    const doc = nlp(word);
    const terms = doc.terms()?.syllables();
    const result = terms.map((term) => {
      let syllables = term?.syllables;
      return syllables
    });

    console.log(result)

    console.log(getSyllablesRiTa(word))

    setSyllables(result)
  }

  // console.log(nlp(word).terms().pronounce()[0]?.pronounce);
  return {getSyllables,syllables};
};

export default useSyllables;
