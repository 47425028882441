import React, { Component } from "react";
import { Button, Modal, Icon, Collapse } from "antd";
import { connect } from "react-redux";
import Swal from "sweetalert";
import { bindActionCreators } from "redux";
import * as activityActions from "store/activities/actions";
import FreadoIcon from "assets/image/freado.svg";
import Quiz from "components/Quiz";
import MediaViewer from "components/MediaViewer";
import CloseBlackIcon from "assets/image/close-black.svg";
import "./activityModal.scss";
import { Mixpanel } from "../../Mixpanel";
import { Analytics } from "../../services/analytics";
import * as EVENT from "../../utils/eventKeys";
import { storage as LocalStorage } from "services/config/storage";
import { v4 as uuidv4 } from "uuid";
import default_thumbnail from "assets/image/default_thumbnail.png";
import { formatDate } from "../../utils/commonUtils";
import version from "../../../package.json";
import CreateAssignment from "../AddAssignment/CreateAssignment/CreateAssignment";

class activityModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quizOpened: false,
      mediaOpen: false,
      isLiked: false,
      storyData: {},
      activeSectionData: {},
      viewId: uuidv4(),
      createAssignmentModalOpen: false,
    };
  }

  lastQuestionAnswered = async (data) => {
    this.setState({ quizOpened: false });
    // console.log("activity_data:",data,this.state);
    let streakPayload = {
      action_id: this.state.storyData.id,
      action_type: "activity",
    };
    // call createStreakRequest using streakPayload
    const { result } = await this.props.createStreakRequest(streakPayload);
    console.log("result:", result);
    if (!result.IsUpdated) {
      // append streak to the dataStory variable
      data.streak = 1;
    }
    this.props.openSuccessModal(data);
  };

  bookmark = async (e) => {
    e.stopPropagation();
    const { bookmarkStory, data } = this.props;
    const res = await bookmarkStory(data.id);
    if (res && res.success) {
      this.setState((prevState) => ({ isLiked: !prevState.isLiked }));
    }
  };

  componentDidMount() {
    const { data } = this.props;
    this.setState({ isLiked: data.is_liked, storyData: data });
    this.sendAnalytics(EVENT.START_ACTIVITY, data);
    if (this.props.isActivityPack) {
      this.sendPacAnalytics(EVENT.VIEW_ACTIVITY_PACK, data);
    }
  }
  componentWillUnmount() {
    const { data } = this.props;
    // this.sendAnalytics(EVENT.CLOSE_ACTIVITY,data)
  }

  sendPacAnalytics = (event, data) => {
    let payload = {};
    payload[EVENT.ACTIVITY_PACK_NAME] = data.name;
    payload[EVENT.SECTION_ID] = this.props.section; //
    payload[EVENT.SOURCE_PAGE_ID] = this.props.source;
    payload[EVENT.ACTIVITY_PACK_ID] = data?.activity_pack;

    Analytics.logEvents(event, payload);
  };

  sendAnalytics = (event, data) => {
    let payload = {};

    payload[EVENT.ACTIVITY_NAME] = data?.name;
    payload[EVENT.ACTIVITY_ID] = data?.id;
    payload[EVENT.SECTION_ID] = this.props.section; //
    payload[EVENT.SOURCE_PAGE_ID] = this.props.source;
    payload[EVENT.ACTIVITY_PACK_ID] = data?.activity_pack;
    payload[EVENT.VIEW_ID] = this.state.viewId;
    Analytics.logEvents(event, payload);
  };

  finishQuiz = async () => {
    const { storyData } = this.state;
    const { data } = this.props;
    const res = await this.props.finishStoryRequest(data.id);
    if (data.has_mcq) {
      this.setState({ quizOpened: true });
    }
  };

  handleActivityReading = (media) => {
    this.setState({ mediaOpen: true });
    this.setState({ activeSectionData: media });
  };

  finishActivity = async () => {
    if (this.props.isActivityPack) {
      // Mixpanel.track("Redeem Activity Pack Click", this.props.data);
    } else if (this.props.isReadingChallenge) {
      // Mixpanel.track("Reading Challenge - We did it Click", this.props.data);
    } else {
      // Mixpanel.track("Basic Activity - We did it Click", this.props.data);
    }
    this.sendAnalytics(EVENT.COMPLETE_ACTIVITY, this.props.data);
    if (this.props.questions && this.props.questions.length > 0) {
      this.setState({ quizOpened: true });
    } else {
      alert("No questions found in the activity...");
    }

    if (
      this.props.data?.status === "completed" &&
      this.props.source === EVENT.SOURCE_GUIDED_PATH_PAGE
    ) {
      this.props.callChangeTaskStatusRequest(this.props.data);
    }
  };

  unlockPack = async () => {
    let payload = {
      activity_pack: this.props.data.id,
    };
    const res = await this.props.unlockActivityPackRequest(payload);

    console.log("here", res);

    const { success } = res;

    if (success) {
      this.props.getLockedActivityPacks();
      this.props.getUnLockedActivityPacks();
      // this.setState({ mediaOpen: false });
      this.props.close(this.props.data.id, true, this.props.data.grade);
      console.log(this.props.data.id, "");
    } else {
      const { error_message } = res.data.error;
      Swal(error_message + " points");
    }
  };

  handleActivityCardClick = async (id) => {
    this.props.close(id);
  };

  convertNumberToWords = (amt) => {
    let amount = amt;
    let words = new Array();
    words[0] = "Zero";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();
    let atemp = amount.split(".");
    let number = atemp[0].split(",").join("");
    let n_length = number.length;
    let words_string = "";
    if (n_length <= 9) {
      let n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      let received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      let value = "";
      for (let i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  };

  render() {
    const { data, questions, isActivityPack, isReadingChallenge } = this.props;
    const { quizOpened, isLiked, activeSectionData, storyData } = this.state;

    console.log(data);

    const Panel = Collapse.Panel;
    const totalActivities =
      isActivityPack && data.activity
        ? this.convertNumberToWords(data.activity.length)
        : "";
    return (
      <div className="component-activity">
        <div className="flex left">
          <img
            src={isActivityPack ? data.image : data.background_image}
            alt="book-coverImg"
            className="bg-image"
          />
          <div className="left-top">
            <div className="mobile-back" onClick={this.props.close}>
              <Icon type="left" />
              <span style={{ textShadow: "0px 2px 3px #333" }}>Back</span>
            </div>
            <div className="left-heading">Individual Activity</div>
          </div>
          <div className="story-card">
            {isActivityPack && data.status === "locked" && (
              <div className="pack-points">
                <Pill icon={FreadoIcon} text={data.freadom_point} />
              </div>
            )}
            <div className="name">{data.name}</div>
            <div className="author-name">
              <b>{data.description}</b>
            </div>
          </div>
        </div>
        <div className="division" />
        <div className="flex description-card">
          <img
            src={CloseBlackIcon}
            className="close-icon"
            alt="close"
            onClick={this.props.close}
          />
          <div
            className="description-container"
            style={{
              height: quizOpened ? "90%" : "80%",
              marginBottom: quizOpened ? "22px" : "102px",
            }}
          >
            {isActivityPack && (
              <p className="total-activities-text">
                {totalActivities.toUpperCase()} ACTIVITIES
              </p>
            )}
            {!quizOpened && !isActivityPack && data.section_data.length > 0 && (
              <React.Fragment>
                <div className="story_skills_section_container">
                  {data.skill_tags?.map((skill) => (
                    <div className="story_skill_container">#{skill}</div>
                  ))}
                </div>
                <div className="flex summary-card">
                  <Collapse
                    accordion
                    expandIconPosition="right"
                    bordered={false}
                    expandIcon={({ isActive }) => (
                      <Icon type="down" rotate={isActive ? -180 : 0} />
                    )}
                    defaultActiveKey={[data.section_data[0].id]}
                    className="summary-collapse"
                  >
                    {data.section_data.map((s) => (
                      <Panel
                        header={s.name}
                        key={s.id}
                        className="summary-collapse-panel"
                      >
                        {s.description !== "" && (
                          <div
                            className="summary"
                            dangerouslySetInnerHTML={{ __html: s.description }}
                          ></div>
                        )}
                        <div className="summary-collapse-panel-media-slider">
                          {s.media.length > 0 &&
                            s.media.map((m, index) => (
                              <div
                                className="summary-collapse-panel-img-container"
                                key={index}
                                onClick={() => this.handleActivityReading(s)}
                              >
                                {/* <img
                                  src={
                                    m.thumbnail_url
                                      ? m.thumbnail_url
                                      : m.media_url
                                  }
                                  alt="Media thumbnail"
                                /> */}
                                {m.media_url ? (
                                  m.media_url.endsWith(".jpg") ||
                                  m.media_url.endsWith(".png") ? (
                                    <img
                                      src={m.media_url}
                                      alt="Media thumbnail"
                                    />
                                  ) : (
                                    <img
                                      src={default_thumbnail}
                                      alt="Media thumbnail"
                                      width={100}
                                      height={100}
                                      index={index}
                                    />
                                  )
                                ) : m.thumbnail_url.endsWith(".jpg") ||
                                  m.thumbnail_url.endsWith(".png") ? (
                                  <img
                                    src={
                                      m.thumbnail_url
                                        ? m.thumbnail_url
                                        : m.media_url
                                    }
                                    alt="Media thumbnail"
                                  />
                                ) : (
                                  <img
                                    src={default_thumbnail}
                                    alt="Media thumbnail"
                                    width={100}
                                    height={100}
                                  />
                                )}
                              </div>
                            ))}
                        </div>
                      </Panel>
                    ))}
                  </Collapse>
                </div>
              </React.Fragment>
            )}

            {data.section_data && data.section_data.length <= 0 && (
              <div className="summary-card">No media in this story...</div>
            )}

            {isActivityPack === true && (
              <div className="flex activity-cards-section">
                <div className="flex activity-cards-container">
                  {data.activity.map((a) => (
                    <div
                      className="card"
                      key={a.id}
                      style={
                        data.status === "unlocked" ? { cursor: "pointer" } : {}
                      }
                      onClick={() =>
                        data.status === "unlocked"
                          ? this.handleActivityCardClick(a.id)
                          : ""
                      }
                    >
                      <div className="card-img">
                        <img src={a.image} alt="activity thumbnail"></img>
                      </div>
                      <div className="card-title">{a.name}</div>
                      <div className="card-text activity-status">
                        {a.status}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {quizOpened && (
              <Quiz
                questions={questions}
                activityData={this.props.data}
                finalAnswer={(data) => this.lastQuestionAnswered(data)}
                closeQuiz={() => this.setState({ quizOpened: false })}
                isStory={false}
              />
            )}
          </div>
          {data.status === "unlocked" ||
          (isActivityPack && totalActivities === "")
            ? ""
            : !quizOpened && (
                <div
                  className="btn-container"
                  style={{
                    padding: "25px 22.5px",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "60%",
                  }}
                >
                  {LocalStorage.fetch.parentData()?.is_teacher &&
                  this.props.source !== EVENT.SOURCE_GUIDED_PATH_PAGE ? (
                    <Button
                      type="primary"
                      className="primary-btn"
                      size="large"
                      onClick={() =>
                        this.setState({ createAssignmentModalOpen: true })
                      }
                    >
                      Assign
                    </Button>
                  ) : (
                    <div />
                  )}
                  <Button
                    type="primary"
                    className="primary-btn"
                    size="large"
                    onClick={
                      isActivityPack ? this.unlockPack : this.finishActivity
                    }
                  >
                    {/* {console.log(storyData.status, isReadingChallenge)} */}
                    {data.status === "completed"
                      ? "Review Quiz"
                      : data.status === "locked"
                      ? "Unlock this pack"
                      : isReadingChallenge
                      ? "Start Challenge"
                      : "We did it"}
                  </Button>
                </div>
              )}
          {/* { !quizOpened && <div className="btn-container" style={{ padding: "25px 22.5px", display: ((storyData.status === 'unlocked') || (isActivityPack && totalActivities === '')) ? 'none' : 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="primary"
              className="primary-btn"
              size="large"
              onClick={ isActivityPack ? this.unlockPack : this.finishActivity }
            >
              { (storyData.status === 'completed')
                  ? 'Review Quiz'
                  : (storyData.status === 'locked'
                    ? 'Unlock this pack'
                    : (isReadingChallenge
                      ? 'Start Challenge'
                      : 'We did it')) }
            </Button>
          </div> } */}
        </div>
        <Modal
          centered
          width="100%"
          className="media-container"
          visible={this.state.mediaOpen}
          onCancel={() => this.setState({ mediaOpen: false })}
          footer={null}
          key={1}
          destroyOnClose
        >
          {console.log("activeSectionData:", activeSectionData)}
          <MediaViewer
            data={[activeSectionData]}
            closeMedia={() => this.setState({ mediaOpen: false })}
            activityPage={true}
          />
        </Modal>
        <Modal
          centered
          visible={this.state.createAssignmentModalOpen}
          onCancel={() => this.setState({ createAssignmentModalOpen: false })}
          footer={null}
          key={1}
          destroyOnClose
          closable={false}
          style={{ minWidth: "40vw", width: "100%" }}
        >
          <CreateAssignment
            data={{
              id: this.props.data?.id,
              name: this.props.data?.name,
              content_type: "activity",
            }}
            type={"Activity"}
            onCloseModal={() => {
              this.setState({ createAssignmentModalOpen: false });
            }}
          />
          {/* <AddAssignment/> */}
        </Modal>
      </div>
    );
  }
}

const Pill = (props) => (
  <div
    className="read-time"
    //FREAD-941
    // style={{
    //   width: props.text ? "" : "26px",
    //   height: props.text ? "" : "26px",
    //   borderRadius: props.text ? "" : "50%",
    // }}
  >
    <img
      src={props.icon}
      style={{ margin: props.text ? "0 6px 0 0" : "auto" }}
      alt=""
    />
    {
      //FREAD-941
    }
    {/* unlock using {props.text || ""} points */}
    {props.text ? "unlock using " + props.text + " points " : ""}
  </div>
);

export default connect(null, (dispatch) =>
  bindActionCreators({ ...activityActions }, dispatch)
)(activityModal);
