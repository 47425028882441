import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as storyActions from "store/story/actions";
import _debounce from 'lodash/debounce';
import * as EVENT from '../../utils/eventKeys'
import { Analytics } from "../../services/analytics";
import { v4 as uuidv4 } from "uuid";
import EpubReader from "../../components/EpubReader/EpubReader";
import useTTSComponent from "../../hooks/useTTS";
import Quiz from "components/Quiz";
import { getTextNodes } from '../../utils/commonFuncs';

const EpubReaderView = (props) => {

    const [isReading, setIsReading] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [locationChanged, setLocationChanged] = useState(false)
    const [lastPage, setLastPage] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [selectedVoice, setSelectedVoice] = useState('en-IN-NeerjaNeural');
    const [tocLength, setTocLength] = useState(0)
    const [playbackRate, setPlaybackRate] = useState(1)
    const [selections, setSelections] = useState([])
    const [isAudioLoading, setIsAudioLoading] = useState(false)
    const [storyData, setStoryData] = useState({});
    const [showButtons,setShowButtons] = useState(false)
    const [state, setState] = useState({
        quizOpened: false,
        continueQuiz: false,
        mediaOpen: false,
        isLiked: false,
        viewId: uuidv4(),
        total_time: 0,
    });

    const renditionRef = useRef(null);
    const viewerRef = useRef(null);
    const textNodesRef = useRef([]);
    const audioRef = useRef(null)
    const view_id = uuidv4()

    const {
        handleTimeUpdate,
        generateTTS,
        currentWordIndex,
        isLoading,
    } = useTTSComponent(audioRef);

    const setTextNodes = () => {
        if (viewerRef.current) {
            const doc_body = viewerRef.current.querySelector('iframe')?.contentWindow?.document
            let textNodes = getTextNodes(doc_body);
            textNodesRef.current = textNodes
            getTTS(playbackRate,selectedVoice)
        }
    }

    const getTTS = (rate, voice) => {
        let contentStr = ``
        // let contentStr = textNodes.filter((tn) => tn.type === 'text')?.map((node) => node.content)
        for (let ind = 0; ind < textNodesRef.current.length; ind++) {
            const node = textNodesRef.current[ind];
            if (node.type === "text") {
                const content = node.content;
                contentStr = contentStr + content
                contentStr = contentStr + ' '
            } else {
                continue
            }
        }
        // console.log(contentStr)
        generateTTS(contentStr, rate ? rate : playbackRate, voice)
    }

    useEffect(() => {
        setStoryData(props.dataStory);
    }, []);


    useEffect(() => {
        if (!isLoading && isAudioLoading) {
            setIsAudioLoading(false)
            audioRef.current.play()
        }
    }, [isLoading])

    useEffect(() => {
        if (viewerRef.current && isLoaded) {
            setTextNodes()
        }
    }, [isLoaded])

    useEffect(()=>{
        let crossIcon = document.querySelector('.timer .icon-close')

        if((currentPageIndex === tocLength -1) && crossIcon && window.innerWidth>650){
            crossIcon.style.display ="none"
        }else{
            crossIcon.style.display="block"
        }

    },[lastPage,currentPageIndex])


    const handleNext = async () => {
        if (currentPageIndex < tocLength) {
            await renditionRef.current.next();
            if (locationChanged) {
                setShowButtons(true)
                setCurrentPageIndex(currentPageIndex + 1)
                setLocationChanged(false)
                if (renditionRef.current.currentLocation()?.atEnd) {
                    setLastPage(true)
                }
            }
            setTextNodes()
        } else {
            setLastPage(true)
        }
    };

    const handlePrev = async () => {
        await renditionRef.current.prev();
        if(renditionRef.current?.currentLocation()?.atStart) setShowButtons(false)
        setLastPage(false)
        setCurrentPageIndex(currentPageIndex - 1)
        setTextNodes()
    };

    const startReading = () => {
        if (!isLoading) {
            try {
                audioRef.current.play();
                setIsAudioLoading(false)
            } catch (error) {
                console.error("Error in TTS synthesis:", error);
                setIsAudioLoading(false)
            }
        } else {
            setIsAudioLoading(true)
        }
    };

    const handleBookLoaded = (book) => {
        // const toc = renditionRef?.current?.book?.navigation?.toc; // Access the TOC from the book
        const toc = renditionRef?.current?.book?.spine?.items; // Access the TOC from the book
        setTocLength(toc?.length); // Set the length of the TOC

        if (viewerRef.current) {
            const iframeDocument = viewerRef.current.querySelector('iframe')?.contentWindow?.document
            // Find the image element within the iframe content
            let body = iframeDocument.querySelector("body");
            let image = iframeDocument.querySelector("img");
            if (body) {
                body.style.display = "flex";
                body.style.flexDirection = "column";
                body.style.justifyContent = "center";
                body.style.gap = "1rem";
                body.style.alignItems = "center";
            }

            if(renditionRef?.current?.currentLocation()?.atStart){
                if(image){
                    image.style.setProperty("width", "95%", "important");
                    image.style.setProperty("max-width", "none", "important");
                    image.style.setProperty("max-height", "600px", "important");
                    image.style.setProperty("object-fit", "cover", "important");
                }
            }
        };
    }

    const handleSelectedVoice = (value) => {
        audioRef.current.pause()
        setSelectedVoice(value)
        getTTS(playbackRate, value)
    }

    const finishQuiz = async () => {

        props?.sendStoryAnalytics(EVENT.MARK_COMPLETE_STORY, storyData);
        const { dataStory } = props;
        if (dataStory.is_quiz_taken || dataStory?.status === 'completed') {
            props.closeModal();
            return;
        }
        let res = await props.finishStoryRequest(storyData.id);
        if (res) {
            await props.closeModal();
            if (dataStory.has_mcq) {
                // setState({ quizOpened: true });
                // Mixpanel.track("QA-After Finish Story", this.props.dataStory);
                props.openQuiz()
            } else if (dataStory?.status !== 'completed') {
                props.updateDetailedStory(res.status);
                setStoryData((prevState) => ({
                    storyData: {
                        ...prevState.storyData,
                        status: res.status,
                    },
                }));
                let streakPayload = {
                    action_id: storyData.id,
                    action_type: "story",
                };
                // call the createStreakRequest to update the streak
                const { result } = await props.createStreakRequest(streakPayload);

                if (!result.IsUpdated) {
                    // append streak to the dataStory variable
                    res.streak = 1;
                }

                props.openSuccessModal(
                    res.result ? res.result : res.data ? res.data : res
                );
            } else {
                props.closeModal();
                return;
            }
        }
    };

    const lastQuestionAnswered = async (dataStory) => {
        setState({ quizOpened: false });

        let streakPayload = {
            action_id: storyData.id,
            action_type: "story",
        };
        // call the await createStreakRequest to update the streak
        const { result } = await props.createStreakRequest(streakPayload);
        if (!result.IsUpdated) {
            // append streak to the dataStory variable
            dataStory.streak = 1;
        }
        props.openSuccessModal(dataStory);
    };

    return (
        <div className='epub_reader_view_container'>
            <EpubReader url={props?.url} bookLoadedFunc={handleBookLoaded} isLoaded={isLoaded} handleNextFunc={handleNext} handlePrevFunc={handlePrev} renditionRef={renditionRef} setIsLoaded={setIsLoaded} currentPageIndex={currentPageIndex} lastPage={lastPage} viewerRef={viewerRef} tocLength={tocLength} setLocationChanged={setLocationChanged} selections={selections} setSelections={setSelections} readAloudFunc={startReading} isAudioLoading={isAudioLoading} readAloudAudioRef={audioRef} playbackRate={playbackRate} setPlayBackRate={setPlaybackRate} currentWordIndex={currentWordIndex} showButtons={showButtons} handleSelectedVoice={handleSelectedVoice} selectedVoice={selectedVoice} finishStory={finishQuiz} isReadAloud={isReading} setIsReadAloud={setIsReading} isPaused={isPaused} setIsPaused={setIsPaused} sendStoryAnalytics={props?.sendStoryAnalytics}

            />
            <audio
                ref={audioRef}
                controls
                onTimeUpdate={handleTimeUpdate}
                onEnded={() => {setIsPaused(false);setIsReading(false)}}
                style={{ display: "none" }}
            ></audio>

            {state.quizOpened && (
                <div className="quiz_container">
                    <Quiz
                        viewId={state.viewId}
                        source={props.source}
                        questions={props.questions}
                        finalAnswer={lastQuestionAnswered}
                        closeQuiz={() => setState({ quizOpened: false })}
                        storyData={storyData}
                        isStory={true}
                    />
                </div>
            )}

        </div>
    );
};

export default connect(
    ({ detailedStory }) => ({ ...detailedStory }),
    (dispatch) => bindActionCreators({ ...storyActions }, dispatch)
)(EpubReaderView);

