import React, { useState } from "react";
import { connect } from "react-redux";
import "./index.scss";
import { bindActionCreators } from "redux";
import * as teacherActions from "../../../../../store/teacher/actions";

import icon1 from "../../../../../assets/image/guided_path_icon1.png";


const GuidedPathUnitsCard = ({
  openGPUnitsModal,
}) => {

  return (
    <div className="osahan-account-page-left shadow-sm bg-white rounded-6 mt-4 rounded border-bottom head-teacher">
      <div className="p-3">
        <div className="osahan-user-media">
          <div className="osahan-user-media-body">
            <div className="cardTitle-row">
              <div className="cardTitle cardTitleSB">
                <div>
                  <img className="guided_path_icon" src={icon1} />

                    Guided Path Units

                </div>
                <a
                  onClick={() => {
                   
                    openGPUnitsModal();
                  }}
                  //FREAD-1342
                  id="FLV_BATCH_VIEW_ALL"
                  //FREAD-1342
                >
                  View All
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({ reduxData: { ...state } }),
  (dispatch) =>
    bindActionCreators(
      {
        ...teacherActions,
      },
      dispatch
    )
)(GuidedPathUnitsCard);
