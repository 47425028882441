export const STORY_DATA = {
  fishInTheOcean: {
    name: "Fish in the Ocean",
    url: "https://s3.ap-south-1.amazonaws.com/cm-fluency/epub-files/Fish_in_the_Ocean.epub",
  },
  goingOnAWalk: {
    name: "Going on a Walk",
    url: "https://s3.ap-south-1.amazonaws.com/cm-fluency/epub-files/Going_on_a_Walk.epub",
  },
  visitToMyClassroom: {
    name: "A Visit To My Classroom",
    url: "https://s3.ap-south-1.amazonaws.com/cm-fluency/epub-files/A_Visit_To_My_Classroom.epub",
  },
  visitToTheBakery: {
    name: "A Visit to the Bakery",
    url: "https://s3.ap-south-1.amazonaws.com/cm-fluency/epub-files/A_Visit_to_the_Bakery.epub",
  },
  theNewShawl: {
    name: "The New Shawl",
    url: "https://s3.ap-south-1.amazonaws.com/cm-fluency/epub-files/The_New_Shawl.epub",
  },
  aRainyDay: {
    name: "A Rainy Day",
    url: "https://s3.ap-south-1.amazonaws.com/cm-fluency/epub-files/A_Rainy_Day.epub",
  },
  allAboutOranges: {
    name: "All About Oranges",
    url: "https://s3.ap-south-1.amazonaws.com/cm-fluency/epub-files/All_About_Oranges.epub",
  },
  everydayRobots: {
    name: "Everyday Robots",
    url: "https://s3.ap-south-1.amazonaws.com/cm-fluency/epub-files/Everyday_Robots.epub",
  },
  allAboutElephants: {
    name: "All About Elephants",
    url: "https://s3.ap-south-1.amazonaws.com/cm-fluency/epub-files/All_About_Elephants.epub",
  },
  learningToRideMyBike: {
    name: "Learning to Ride My Bike",
    url: "https://s3.ap-south-1.amazonaws.com/cm-fluency/epub-files/Learning_to_Ride_My_Bike.epub",
  },
  schoolAroundTheWorld: {
    name: "School Around the World",
    url: "https://s3.ap-south-1.amazonaws.com/cm-fluency/epub-files/School_Around_the_World.epub",
  },
  dogsWhoWork: {
    name: "Dogs Who Work",
    url: "https://s3.ap-south-1.amazonaws.com/cm-fluency/epub-files/Dogs_Who_Work.epub",
  },
  howDoParrotsLearnToTalk: {
    name: "How Do Parrots Learn to Talk",
    url: "https://s3.ap-south-1.amazonaws.com/cm-fluency/epub-files/How_Do_Parrots_Learn_to_Talk.epub",
  },
  letsGoToTheZoo: {
    name: "Lets Go to the Zoo",
    url: "https://cm-fluency.s3.ap-south-1.amazonaws.com/epub-files/Lets_Go_to_the_Zoo+(1).epub",
  },
  allAboutLeopards: {
    name: "All About Leopards",
    url: "https://s3.ap-south-1.amazonaws.com/cm-fluency/epub-files/All_About_Leopards.epub",
  },
  allAboutEggs: {
    name: "All About Eggs",
    url: "https://s3.ap-south-1.amazonaws.com/cm-fluency/epub-files/All_About_Eggs.epub",
  },
  visitToMySchool: {
    name: "A Visit to My School",
    url: "https://s3.ap-south-1.amazonaws.com/cm-fluency/epub-files/A_Visit_to_My_School.epub",
  },
};