export const getTextNodes = (doc) => {
    const extractedContent = [];
    // Helper function to process each element and its children recursively
    const processElement = (element, index) => {
        // Process paragraph <p> elements
        if (element.tagName.toLowerCase() === 'p') {
            const text = element.textContent.trim();
            if (text) {
                extractedContent.push({
                    type: 'text',
                    content: text,
                    index: `text-${index}`,
                });
            }

            // Extract images inside the <p> element
            const imagesInParagraph = element.querySelectorAll('img');
            imagesInParagraph.forEach((img, imgIndex) => {
                extractedContent.push({
                    type: 'image',
                    src: img.getAttribute('src'),
                    index: `image-${index}-${imgIndex}`,
                });
            });
        }
        // Process image <img> elements directly
        else if (element.tagName.toLowerCase() === 'img') {
            extractedContent.push({
                type: 'image',
                src: element.getAttribute('src'),
                index: `image-${index}`,
            });
        }
        // Process heading <h2> elements
        else if (element.tagName.toLowerCase() === 'h2') {
            const text = element.textContent?.trim();
            if (text) {
                extractedContent.push({
                    type: 'heading',
                    content: text,
                    index: `heading-${index}`,
                });
            }
            const imagesInParagraph = element.querySelectorAll('img');
            imagesInParagraph.forEach((img, imgIndex) => {
                extractedContent.push({
                    type: 'image',
                    src: img.getAttribute('src'),
                    index: `image-${index}-${imgIndex}`,
                });
            });
        }
        // Process subheading <h3> elements
        else if (element.tagName.toLowerCase() === 'h3') {
            const text = element.textContent?.trim();
            if (text) {
                extractedContent.push({
                    type: 'subheading',
                    content: text,
                    index: `subheading-${index}`,
                });
            }
            const imagesInParagraph = element.querySelectorAll('img');
            imagesInParagraph.forEach((img, imgIndex) => {
                extractedContent.push({
                    type: 'image',
                    src: img.getAttribute('src'),
                    index: `image-${index}-${imgIndex}`,
                });
            });
        }
        // Process <div> elements and recursively handle their children
        else if (element.tagName.toLowerCase() === 'div') {
            // Recursively process all child nodes of the current div
            Array.from(element.children).forEach((child, childIndex) => {
                processElement(child, `${index}-${childIndex}`);
            });
        }

        // Add more checks if needed for other content types (e.g., videos, audio, etc.)
    };

    // Start processing the children of the body element
    Array.from(doc.body.children).forEach((child, index) => {
        processElement(child, index);
    });
    return extractedContent;
};