import React, { Component } from "react";
import "./ReadandSpeakLoader.scss";

const ReadandSpeakLoader = () => {
  return (
    <div className="read-and-speak-loader">
      <div className="three-dots">
        <span className="dot" />
        <span className="dot" />
        <span className="dot" />
      </div>
    </div>
  );
}

export default ReadandSpeakLoader
