import React from 'react'
import { MdOutlineClose } from "react-icons/md";
import './syllablesModal.scss'

const SyllablesModal = ({ word, data, closeModal }) => {
    // const meanings =data &&  data[1]?.value?.split('\n')
    let totalCount = 0
    data?.forEach((syllable)=>{
        totalCount+=syllable?.length
    })
    return (
        <div className='syllables_modal_container'>
            {
                data &&
                <>
                    <div className='syllables_modal_header'>
                        <p>{`Syllables of ${word}`}</p>
                        <MdOutlineClose onClick={() => closeModal()} className='close_icon' />
                    </div>

                    <div className='syllables_modal_definition'>
                        <div className='syllables_container'>
                            <p className='syllables_key'>{`Count: `}</p>
                            <p className='syllables_value'>{totalCount}</p>
                        </div>
                        <div className='syllables_container'>
                            <p className='syllables_key'>{`Syllables breakdown: `}</p>
                            <p className='syllables_value'>{data?.map((syllable)=>syllable?.join("-"))?.join(" ")}</p>
                        </div>

                    </div>

                </>
            }
        </div>
    )
}

export default SyllablesModal